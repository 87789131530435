function __translate(key, replace = {}, variants = null) {
    let sub = key.split('.', 2)
    let translation
    if (variants && variants[sub[0]]) {
        translation = variants[sub[0]];
        if (sub[1]) {
            if (translation) {
                return __translate(sub[1], replace, translation)
            } else {
                return key
            }
        }
    } else {
        return key
    }
    Object.keys(replace).forEach(function (key) {
        translation = translation.replace(':' + key, replace[key])
    });
    return translation
}

export default {
    methods: {
        __(key, replace = {}, variants = null) {
            variants = variants ? variants : this.$page.props.language;
            return __translate(key, replace, variants)
        },
    },
}


import {router} from "@inertiajs/vue3";

export const inertiaRoutingInstrumentation = (
    customStartTransaction,
    startTransactionOnPageLoad = true,
    startTransactionOnLocationChange = true,
) => {
    let activeTransaction;
    let name;
    if (startTransactionOnPageLoad) {
        name = route().current();
        activeTransaction = customStartTransaction({
            name,
            op: 'pageload',
            metadata: {
                source: 'route',
            },
        });
    }

    if (startTransactionOnLocationChange) {
        router.on('before', (_to, _from) => {
            if (activeTransaction) {
                activeTransaction.finish();
            }

            const newName = route().current();

            if (newName !== name) {
                activeTransaction = customStartTransaction({
                    name: newName,
                    op: 'navigation',
                    metadata: {
                        source: 'route',
                    },
                });
            }
        });

        router.on('finish', () => {
            activeTransaction.setName(route().current(), 'route');
        });
    }
}
